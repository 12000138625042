import { render, staticRenderFns } from "./homepage.vue?vue&type=template&id=3066cb95&"
import script from "./homepage.vue?vue&type=script&lang=js&"
export * from "./homepage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SupportWidget: require('/codebuild/output/src2078879480/src/crearistorante-frontend/components/support/SupportWidget.vue').default})
