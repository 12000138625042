export default {
  getAnalisiMercatoPercentage(state) {
    let completedFields = 0;
    let fieldCount = 0;
    for (const key in state.analisiMercato.fields) {
      fieldCount++;
      if (
        state.analisiMercato.fields[key] &&
        state.analisiMercato.fields[key] != ""
      ) {
        completedFields++;
      }
    }
    return parseInt((completedFields / fieldCount) * 100);
  },
  getLocalePercentage(state) {
    switch (state.locale.saveCounts) {
      case 2:
        return 20;
      case 3:
        return 40;
      case 4:
        return 100;
      default:
        return 0;
    }
  },
  getMenuPercentage(state) {
    switch (state.menu.saveCounts) {
      case 2:
        return 20;
      case 3:
        return 80;
      case 4:
        return 100;
      default:
        return 0;
    }
  },
  getAnalisiPercentage(state) {
    const completedSteps = Math.min(state.analisi.saveCounts, 6);
    return parseInt(((completedSteps - 1) / 5) * 100);
  },
  getAdempimentiBurocraticiPercentage(state) {
    let count = 0;
    let checked = 0;

    for (const prop in state.adempimentiBurocratici.fields) {
      if (Object.hasOwn(state.adempimentiBurocratici.fields, prop)) {
        count++;
        if (state.adempimentiBurocratici.fields[prop]) {
          checked++;
        }
      }
    }

    return parseInt((checked / count) * 100);
  },
};
