export default { 
  homepageUrl: 'https://staging.d2c1n6xyho4m2y.amplifyapp.com',
  googleRedirectLink: 'https://apriristorante.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&client_id=6ap1glpl39loduu8upaqkve1ii&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://servizi.crearistorante.it/private/google&identity_provider=Google', 
  apiUrl: 'https://iit5o42jik.execute-api.eu-west-1.amazonaws.com/staging', 
  partners: { 
    apiUrl: 'https://7w7poik404.execute-api.eu-west-1.amazonaws.com/staging', 
    publicApiUrl: 'https://gwsaj76764.execute-api.eu-west-1.amazonaws.com/staging' 
  },
  cognito: {
    baseWebsite: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_DW4DSILc9', 
      clientId: '5feqnbka5et1nf52kt1tdo1hfq'
    },
    partners: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_wLJrL4FQu',
      clientId: '5bq06uv2gcq0kotf530khq7m2d'
    }
  },
  stripe: {
      products: {
        companies: {
          ONE_YEAR: 'https://buy.stripe.com/test_14keYsgpO3NDcla28s',
          SIX_MONTHS: 'https://buy.stripe.com/test_eVabMg6Pe3NDgBq3cv',
          THREE_MONTHS: 'https://buy.stripe.com/test_00gbMgc9ydod0Cs3cu'
        },
        freelancers: {
          ONE_YEAR: 'https://buy.stripe.com/test_6oE2bG1uU5VLdpe6oF',
          SIX_MONTHS: 'https://buy.stripe.com/test_7sI3fK6Pe83T3OEaEU',
          THREE_MONTHS: 'https://buy.stripe.com/test_4gwcQk1uU1Fvdpe00f'
        }
      }
    }
}
