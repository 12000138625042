import storage from "~/assets/js/Storage";

export default function ({ route, redirect }) {
  // Redirect to login page if not authenticated
  if (
    ![
      "ristoratori-autenticazione",
      "ristoratori-autenticazione-registrazione",
      "ristoratori-autenticazione-recupero-password",
      "ristoratori-autenticazione-conferma",
      "consulenza",
      "consulenza-richiesta",
      "consulenza-grazie",
      "consulenza-condizioni-generali-di-contratto"
    ].includes(route.name) &&
    !storage.isAuthenticated()
  ) {
    return redirect("/ristoratori/autenticazione");
  }
}
