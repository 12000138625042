//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import User from "~/assets/js/User";
import storage from "~/assets/js/Storage";

export default {
  name: "RistoratoriMobileTopHeader",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    setIsOpen: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      authenticated: storage.isAuthenticated(),
    };
  },
  computed: {
    username() {
      return this.$localStore.get("name")
        ? this.$localStore.get("name")
        : this.$localStore.get("username");
    },
  },
  methods: {
    signOut() {
      this.$localStore.clearAll();
      this.$cookies.remove("authenticated");
      User.signOut();
      window.location.href = "/";
    },
  },
  watch: {
    $route() {
      this.setIsOpen(false);
    }
  }
};
